<script lang="ts">
export default { name: "CheckoutPage" };

export const enum CheckoutStep {
  ADDRESS_INPUT,
  PAYMENT_SELECTION,
  ORDER_VERIFICATION,
}
</script>

<script setup lang="ts">
import { useGtm } from "@gtm-support/vue-gtm";
import CheckoutOrderSummaryBox from "~/components/checkout/CheckoutOrderSummaryBox.vue";
import CheckoutAddressInput from "~/components/checkout/CheckoutAddressInput.vue";
import type CheckoutPaymentMethods from "~/components/checkout/CheckoutPaymentMethods.vue";

const { t } = useI18n();
const { back: navigateBack } = useRouter();
const { isLoggedIn } = useUser();
const { createOrder, selectedPaymentMethod } = useCheckout();
const {
  isEmpty: cartIsEmpty,
  cartItems,
  totalPrice,
  refreshCart,
  isShipmentSpedi,
} = useCart();

const { updateCustomerAddress } = useAddress();

const { setActiveBillingAddress, activeBillingAddress } = useSessionContext();

const { pushError } = useNotifications();
const { query } = useRoute();
const { refreshSessionContext } = useSessionContext();
const { apiClient } = useShopwareContext();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const handlePaymentExtensions = useHandlePaymentExtensions();
const { lineItemsToTrackingItems } = await useTrackingHelper();
const gtm = useGtm();
// const { getCountries } = useCountries();

const checkoutBox = ref<typeof CheckoutOrderSummaryBox>();

const readyForNextStep = ref(true);

const currentStep = ref(CheckoutStep.ADDRESS_INPUT);
const isLoading = ref(isLoggedIn.value);
const stepTitles = [
  t("checkout.adress"),
  t("checkout.payment"),
  t("checkout.confirmation"),
];
const stepIcons = [
  "i-sl-drawer-home",
  "i-sl-credit-card-dollar-1",
  "i-sl-like-circle",
];

const trackingItems = asyncComputed(
  async () => await lineItemsToTrackingItems(cartItems.value),
  [],
);

// Handle Step Indicator
async function nextStep() {
  if (cartIsEmpty.value) return pushError(t("cart.emptyCartLabel"));

  switch (currentStep.value) {
    case CheckoutStep.ADDRESS_INPUT:
      try {
        isLoading.value = true;
        assert(
          addressComponent.value !== undefined,
          "AddressComponent is mounted and connected",
        );

        if (isShipmentSpedi()) {
          if (addressComponent?.value.needsExtraPhoneNumber) {
            if (!addressComponent.value.extraPhoneNumber) {
              pushError(t("form.phoneNumberRequiredWhenSpedi"));
              return;
            } else {
              if (activeBillingAddress?.value) {
                activeBillingAddress!.value!.phoneNumber =
                  addressComponent.value?.phoneNumber ||
                  addressComponent.value?.extraPhoneNumber;
                await setActiveBillingAddress(activeBillingAddress.value);
                await updateCustomerAddress(activeBillingAddress.value);
              } else {
                throw new Error("Active address not valid");
              }
            }
          }
        }

        // Do we still need this?
        // await addressComponent.value.saveAddresses();

        gtm?.push({ ecommerce: null });
        gtm?.push({
          event: "add_shipping_info",
          ecommerce: {
            currency: useShopCurrency(),
            value: totalPrice.value,
            items: trackingItems.value,
          },
        });
      } catch (e) {
        return;
      } finally {
        isLoading.value = false;
      }

      if (currentStep.value < stepTitles.length - 1) currentStep.value++;

      break;

    case CheckoutStep.PAYMENT_SELECTION:
      if (!paymentComponent.value?.isPaymentMethodValid) {
        pushError(t("checkout.invalidPaymentMethodError"));
        return;
      }

      if (selectedPaymentMethod.value === null) return;
      assert(
        paymentComponent.value !== undefined,
        "PaymentComponent is mounted and connected",
      );
      try {
        isLoading.value = true;
        await paymentComponent.value.onBeforeNextStep();

        gtm?.push({ ecommerce: null });
        gtm?.push({
          event: "add_payment_info",
          ecommerce: {
            currency: useShopCurrency(),
            value: totalPrice.value,
            payment_type: selectedPaymentMethod.value.name,
            items: trackingItems.value,
          },
        });
      } catch (e) {
        pushError(t("checkout.requiredFieldMustBeSet"));
        return;
      } finally {
        isLoading.value = false;
      }

      if (currentStep.value < stepTitles.length - 1) currentStep.value++;
      break;

    case CheckoutStep.ORDER_VERIFICATION:
      isLoading.value = true;

      try {
        const order = await createOrder();

        gtm?.push({ ecommerce: null });
        gtm?.push({
          event: "purchase",
          ecommerce: {
            currency: useShopCurrency(),
            value: order.amountTotal || NaN,
            transaction_id: order.orderNumber,
            tax: (order.amountTotal || NaN) - (order.amountNet || NaN),
            shipping: order.shippingTotal || NaN,
            items: trackingItems.value,
          },
        });

        navigateTo(formatLink(`/checkout/success/${order.id}`));
        refreshCart();
      } catch (error) {
        pushError(error as string); // TODO
      }

      if (currentStep.value < stepTitles.length - 1) currentStep.value++;
      break;
  }
}

function prevStep() {
  if (currentStep.value > 0) currentStep.value--;
  else navigateBack();
}

async function setStep(step: number, isNewShippingAddress?: boolean) {
  currentStep.value = step;
  await nextTick();

  if (
    step === CheckoutStep.ADDRESS_INPUT &&
    isNewShippingAddress &&
    addressComponent.value
  ) {
    addressComponent.value.differingShippingAddress = true;
  }
}

definePageMeta({
  layout: "checkout",
});

if (!!query["amazonCheckoutSessionId"]) {
  currentStep.value = CheckoutStep.ORDER_VERIFICATION;

  const amazonPayCheckoutId = query["amazonCheckoutSessionId"] as string;

  await apiClient.invoke(
    "amazonCheckoutReview post /amazon-pay-checkout-review",
    {
      body: {
        amazonCheckoutSessionId: amazonPayCheckoutId,
      },
    },
  );
  handlePaymentExtensions.value = { amazonPayCheckoutId };

  await refreshSessionContext();
  await refreshCart();
  isLoading.value = false;
}

const addressComponent = ref<typeof CheckoutAddressInput>();
const paymentComponent = ref<typeof CheckoutPaymentMethods>();

// Emits New Step from StepIndicator
const handleStepUpdate = (step: number) => {
  if (step <= currentStep.value) {
    currentStep.value = step - 1;
  }
};

function waitForTrackingItems() {
  const waitForItems = Promise.withResolvers<boolean>();

  const watcherCallback = (items: object[]) => {
    if (items.length > 0) {
      waitForItems.resolve(true);
    }
  };
  const itemsWatcher = watch(trackingItems, watcherCallback);
  watcherCallback(trackingItems.value);

  waitForItems.promise.then(() => {
    itemsWatcher.stop();
  });

  return waitForItems.promise;
}

onMounted(async () => {
  await waitForTrackingItems();

  gtm?.push({ ecommerce: null });
  gtm?.push({
    event: "begin_checkout",
    ecommerce: {
      currency: useShopCurrency(),
      value: totalPrice.value,
      items: trackingItems.value,
    },
  });
});
</script>

<template>
  <div class="mt-10 custom-container">
    <SharedStepIndicator
      class="mx-5 sm:mx-3"
      :current-step="currentStep"
      :num-steps="3"
      :step-titles="stepTitles"
      :step-icons="stepIcons"
      @update-step="handleStepUpdate"
    />
    <div class="md:grid grid-cols-1 lg:grid-cols-3 gap-3 mt-15">
      <div class="md:col-span-2">
        <div v-if="currentStep === CheckoutStep.ADDRESS_INPUT">
          <CheckoutAddressInput
            ref="addressComponent"
            :recalculate-prizes="checkoutBox?.recalculatePrizes"
            @load="isLoading = false"
          />
        </div>
        <div v-if="currentStep === CheckoutStep.PAYMENT_SELECTION">
          <CheckoutPaymentMethods
            ref="paymentComponent"
            v-model:ready-for-next-step="readyForNextStep"
          />
        </div>
        <div v-if="currentStep === CheckoutStep.ORDER_VERIFICATION">
          <CheckoutOrderVerification @set-step="setStep" />
        </div>

        <div class="flex items-center gap-5 mt-3">
          <button
            class="hidden lg:inline-block my-5 px-6 py-2 b b-scheppach-primary-500 rd c-scheppach-primary-500 items-center bg-scheppach-shades-0 hover:bg-scheppach-primary-50"
            @click="prevStep"
          >
            <p class="uppercase">{{ $t("goBack") }}</p>
          </button>
        </div>
      </div>

      <div class="col-span-2 lg:col-span-1 mt-3 md:mt-0">
        <div class="relative h-full">
          <div class="sticky top-26">
            <CheckoutOrderSummaryBox
              ref="checkoutBox"
              :button-label="
                $t(
                  [
                    'checkout.summaryBox.proceedToPayment',
                    'checkout.summaryBox.proceedToConfirm',
                    'checkout.summaryBox.proceedToPurchase',
                  ][currentStep],
                )
              "
              :current-step="currentStep"
              :disable-next-step="
                !readyForNextStep || addressComponent?.isFormExpanded || false
              "
              @next-step="nextStep"
            />
            <div v-if="currentStep == 2" class="mt-3">
              <SharedDetailsSummary
                :title="$t('checkout.promoCode.iHaveAPromoCode')"
                class="mb-3"
              >
                <CheckoutPromotionCode />
              </SharedDetailsSummary>
            </div>
          </div>

          <ScheppachSpinner v-if="isLoading" />
        </div>
      </div>
    </div>
    <button
      class="lg:hidden inline-block my-5 w-full md:w-auto px-6 py-2 b b-scheppach-primary-500 rd c-scheppach-primary-500 items-center bg-scheppach-shades-0 hover:bg-scheppach-primary-700 hover:c-white"
      @click="prevStep"
    >
      <p class="inline-block uppercase">{{ $t("goBack") }}</p>
    </button>
  </div>
</template>
