<script setup lang="ts">
import { CheckoutStep } from "~/pages/checkout/index.vue";
import { BadgeType } from "../product/ProductBadge.vue";
import { getProductRoute } from "@shopware/helpers";

const { query } = useRoute();
const { t } = useI18n();
const { cartItems, isEmpty } = useCart();

// TODO this might not allways work
const { isBWare, salesPercentage, isSet, isActionPrice } =
  useProductState(cartItems);
const { billingAddress, shippingAddress, selectedPaymentMethod } =
  useCheckout();
const { user, isLoggedIn, isGuestSession } = useUser();
const localePath = useLocalePath();
const { loadAmazonPayNamespace } = useAmazonPay();

const emit = defineEmits<{
  (e: "setStep", step: CheckoutStep, isNewShippingAddress?: boolean): void;
}>();

const amazonPayExpressMode = !!query["amazonCheckoutSessionId"];

async function editShippingAddress() {
  if (amazonPayExpressMode) {
    const amazon = await loadAmazonPayNamespace();
    amazon.Pay.changeShippingAddress({
      amazonCheckoutSessionId: query["amazonCheckoutSessionId"] as string,
    });
  } else {
    emit("setStep", CheckoutStep.ADDRESS_INPUT, true);
  }
}

async function editPaymentMethod() {
  if (amazonPayExpressMode) {
    const amazon = await loadAmazonPayNamespace();
    amazon.Pay.changePaymentMethod({
      amazonCheckoutSessionId: query["amazonCheckoutSessionId"] as string,
    });
  } else {
    emit("setStep", CheckoutStep.PAYMENT_SELECTION);
  }
}
</script>

<template>
  <div class="mx-auto w-full">
    <SharedDetailsSummary
      v-if="isLoggedIn || isGuestSession"
      :title="t('checkout.yourContact')"
    >
      <div class="bg-scheppach-shades-0 p-8 rd">
        <h1 class="text-[25px] c-scheppach-primary-500">
          {{ t("checkout.pleaseConfirmYourOrder") }}
        </h1>
        <p class="mt-5 mb-10">
          {{ t("checkout.checkOrderInfoMessage") }}
        </p>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-5 xl:gap-8 xl:mb-7">
          <!-- ########## Rechnungsadresse ########## -->
          <div>
            <p class="text-[20px] mb-0.5 c-scheppach-primary-700">
              {{ t("checkout.billingAddressLabel") }}
            </p>
            <div
              class="b-1 b-scheppach-primary-700 w-auto xl:min-w-[360px] min-h-[210px] rd p-4 line-height-relaxed relative"
            >
              <p v-if="billingAddress?.company">
                {{ billingAddress?.company }}
              </p>
              <p>
                {{ billingAddress?.firstName }} {{ billingAddress?.lastName }}
              </p>
              <p>{{ billingAddress?.street }}</p>
              <p>{{ billingAddress?.zipcode }} {{ billingAddress?.city }}</p>
              <p>{{ billingAddress?.country?.name }}</p>

              <div v-if="!amazonPayExpressMode" class="mt-10">
                <button
                  class="b-b-1 c-scheppach-primary-500 b-scheppach-primary-700 bg-transparent font-700 inline-block absolute bottom-2"
                  @click="emit('setStep', CheckoutStep.ADDRESS_INPUT)"
                >
                  {{ t("checkout.editBillingAddress") }}
                </button>
              </div>
            </div>
          </div>

          <!-- ########## Versandadresse ########## -->
          <div>
            <p class="text-[20px] mb-0.5 c-scheppach-primary-700 pb-0">
              {{ t("checkout.shippingAddressLabel") }}
            </p>
            <div
              class="b-1 b-scheppach-primary-700 w-auto xl:min-w-[360px] min-h-[210px] rd p-4 line-height-relaxed relative"
            >
              <p v-if="shippingAddress?.company">
                {{ shippingAddress?.company }}
              </p>
              <p>
                {{ shippingAddress?.firstName }} {{ shippingAddress?.lastName }}
              </p>
              <p>{{ shippingAddress?.street }}</p>
              <p>{{ shippingAddress?.zipcode }} {{ shippingAddress?.city }}</p>
              <p>{{ shippingAddress?.country?.name }}</p>

              <div class="mt-10">
                <button
                  class="b-b-1 c-scheppach-primary-500 b-scheppach-primary-700 bg-transparent font-700 inline-block absolute bottom-2"
                  @click="editShippingAddress"
                >
                  {{ t("checkout.editDeliveryAddress") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-5 xl:gap-8 mt-5">
          <!-- ########## Rechnungsadresse ########## -->
          <div>
            <p class="text-[20px] font-500 mb-0.5 c-scheppach-primary-700 pb-0">
              {{ t("checkout.contactDetailsLabel") }}
            </p>
            <div
              class="b-1 b-scheppach-primary-700 w-auto xl:min-w-[360px] min-h-[210px] rd p-4 line-height-relaxed relative"
            >
              <p>{{ user?.email }}</p>
              <p>{{ shippingAddress?.phoneNumber }}</p>

              <div v-if="!amazonPayExpressMode" class="mt-10">
                <button
                  class="b-b-1 c-scheppach-primary-500 b-scheppach-primary-700 bg-transparent font-700 inline-block absolute bottom-2"
                  @click="emit('setStep', 0)"
                >
                  {{ t("checkout.editContactDetails") }}
                </button>
              </div>
            </div>
          </div>

          <!-- ########## Zahlungsart ########## -->
          <div>
            <p class="text-[20px] mb-0.5 c-scheppach-primary-700 pb-0">
              {{ t("checkout.paymentMethodLabel") }}
            </p>
            <div
              class="b-1 b-scheppach-primary-700 w-auto xl:min-w-[360px] min-h-[210px] rd p-4 line-height-relaxed relative"
            >
              <div class="flex items-center gap-3">
                <SharedMediaImage
                  v-if="selectedPaymentMethod?.media"
                  :media="selectedPaymentMethod.media"
                  :height="45"
                  class="h-[45px] p-1"
                />
                <p class="text-5 c-scheppach-primary-700">
                  {{ selectedPaymentMethod?.name }}
                </p>
              </div>

              <div class="mt-10">
                <button
                  class="b-b-1 c-scheppach-primary-500 b-scheppach-primary-700 bg-transparent font-700 inline-block absolute bottom-2"
                  @click="editPaymentMethod"
                >
                  {{ t("checkout.editPaymentMethod") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SharedDetailsSummary>

    <SharedDetailsSummary v-if="!isEmpty" class="mt-5">
      <template #title>
        <p>
          {{ t("checkout.yourOrder") }}
          <span class="font-500">
            ({{ t("checkout.articleAmount", { p: cartItems?.length }) }})
          </span>
        </p>
      </template>

      <div class="bg-scheppach-shades-0 rd">
        <div v-for="(item, index) in cartItems" :key="item.id" class="p-4">
          <hr v-if="index > 0" class="bg-scheppach-primary-100 h-2px mb-10" />
          <div class="flex items-center gap-2 mt-3">
            <ProductBadge
              v-if="salesPercentage"
              :badge-type="BadgeType.SALE"
              :text="`${salesPercentage}%`"
            />
            <ProductBadge
              v-if="isBWare"
              :badge-type="BadgeType.BWARE"
              :text="t('product.bStock')"
            />
            <ProductBadge
              v-if="isSet"
              :badge-type="BadgeType.IS_SET"
              :text="t('product.productSet')"
            />
            <ProductBadge
              v-if="isActionPrice"
              :badge-type="BadgeType.IS_ACTION"
              :text="t('product.actionPrice')"
            />
          </div>

          <!-- Product Item -->
          <NuxtLink
            v-if="!item?.payload?.promotionId"
            class="font-500 flex flex-col sm:flex-row gap-10 hover:bg-scheppach-primary-50 duration-100 p-3"
            :to="localePath(getProductRoute(item))"
          >
            <!-- Cart Item Image -->
            <SharedMediaImage
              :media="item?.cover?.media || cartItems?.[index]?.cover"
              class="object-contain w-full h-full max-w-[280px] mx-auto sm:max-w-[200px] sm:h-[200px]"
            />

            <div class="w-full">
              <span>
                <p
                  class="font-700 text-[18px] sm:text-[20px] lh-relaxed c-scheppach-primary-700"
                >
                  {{ item.label }}
                </p>
                <p>
                  {{ t("product.articleNumber") }}:
                  {{ item.payload?.productNumber }}
                </p>
              </span>

              <div class="flex mt-10" :class="'justify-between'">
                <div>
                  <p>{{ t("product.amount") }}</p>
                  <p class="font-700 c-scheppach-primary-500 text-4.5">
                    {{ item.quantity }}
                  </p>
                </div>

                <div class="flex gap-10">
                  <div>
                    <p>{{ t("product.unitPrice") }}</p>
                    <SharedPrice
                      :value="item?.price?.unitPrice"
                      class="font-700 text-[16px] lg:text-[20px] c-scheppach-primary-500"
                    >
                      <template #afterPrice>*</template>
                    </SharedPrice>
                    <SharedPrice
                      v-if="item?.price?.listPrice?.price"
                      :value="item?.price?.listPrice?.price"
                      class="text-[12px] lg:text-[14px] line-through"
                    >
                      <template #afterPrice>*</template>
                    </SharedPrice>
                  </div>
                  <div v-if="item.quantity > 1" class="text-left">
                    <p>{{ t("product.totalPrice") }}</p>
                    <SharedPrice
                      :value="item?.price?.totalPrice"
                      class="font-700 text-[16px] lg:text-[20px] c-scheppach-primary-500"
                    >
                      <template #afterPrice>*</template>
                    </SharedPrice>
                    <SharedPrice
                      v-if="item?.price?.listPrice?.price"
                      :value="item?.price?.listPrice?.price * item?.quantity"
                      class="text-[12px] lg:text-[14px] c-scheppach-neutral-500 line-through"
                    >
                      <template #afterPrice>*</template>
                    </SharedPrice>
                  </div>
                </div>
              </div>
            </div>
          </NuxtLink>

          <!-- Discount Item -->
          <div
            v-else
            class="font-500 flex flex-col sm:flex-row gap-10 p-3"
            :to="localePath(getProductRoute(item))"
          >
            <img
              class="size-45 mx-auto"
              src="../../../public/images/percent.png"
              alt="Percentage Image"
            />

            <div class="w-full">
              <span>
                <p class="text-5 font-300 mb-2.5">
                  {{ t("checkout.promoCode.voucherCode") }}
                </p>
                <p
                  class="font-700 text-[18px] sm:text-5.5 c-scheppach-primary-700 capitalize"
                >
                  {{ item.payload.code }}
                </p>
                <p class="c-scheppach-primary-700 text-4.5">
                  {{ item.label }}
                </p>
              </span>

              <div class="flex mt-10" :class="'justify-end'">
                <div class="flex gap-10">
                  <div>
                    <p class="c-scheppach-primary-700">
                      {{ t("checkout.promoCode.voucher") }}
                    </p>
                    <SharedPrice
                      :value="item?.price?.unitPrice"
                      class="font-700 text-[16px] lg:text-[20px] c-scheppach-primary-500"
                    >
                      <template #afterPrice></template>
                    </SharedPrice>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SharedDetailsSummary>

    <div v-if="isEmpty" class="mt-5">
      <div class="text-center font-700 text-5 bg-white py-4 rd">
        {{ t("cart.emptyCartLabel") }}
      </div>
    </div>
  </div>
</template>
