<script setup lang="ts">
import { CheckoutStep } from "~/pages/checkout/index.vue";

const { t } = useI18n();
const { cart, totalPrice, shippingTotal, subtotal, refreshCart } = useCart();
const { pushError } = useNotifications();
const { getFormattedPrice } = usePrice();
const { getPaymentMethods, selectedPaymentMethod } = useCheckout();
const { PAY_PAL_PAY_UPON_INVOICE_SHORT_NAME } = usePayPal();
const { AMAZON_PAY_PAYMENT_HANDLER } = useAmazonPay();
const { query } = useRoute();

const props = defineProps<{
  buttonLabel: string;
  currentStep: CheckoutStep;
  disableNextStep: boolean;
}>();
const { buttonLabel, currentStep } = toRefs(props);

const emit = defineEmits(["nextStep"]);

const tosAccepted = ref(false);
const requireTos = computed(
  () => currentStep.value == CheckoutStep.ORDER_VERIFICATION,
);
const disabledByTos = computed(() => requireTos.value && !tosAccepted.value);

const disabled = computed(() => disabledByTos.value || props.disableNextStep);
const taxedPrice = computed(() => {
  if (!cart.value) return 0; // needed while loading
  assert(cart.value.price?.netPrice !== undefined, "net price is available");
  return totalPrice.value - cart.value.price.netPrice;
});

function guardeNextStep() {
  if (disabledByTos.value)
    return pushError(t("checkout.termsAndConditionsError"));
  emit("nextStep");
}

const amazonPayExpressMode = !!query["amazonCheckoutSessionId"];

async function recalculatePrizes() {
  await refreshCart();
}

defineExpose({ recalculatePrizes });

await getPaymentMethods();
</script>

<template>
  <div class="px-4 py-2 bg-scheppach-primary-50">
    <h1 class="text-[24px] font-700 c-scheppach-primary-500 mb-5">
      {{ t("checkout.summaryBox.summaryCheckout") }}
    </h1>
    <div class="line-height-loose">
      <div class="flex items-center justify-between">
        <div>
          <p>{{ t("checkout.subtotal") }}</p>
          <p>{{ t("checkout.summaryBox.shipment") }}</p>
          <p>
            {{
              t("checkout.summaryBox.vatAndShippingNotice", {
                p: cart?.price?.calculatedTaxes?.[0]?.taxRate || 0,
              })
            }}
          </p>
        </div>
        <div class="text-right">
          <p>{{ getFormattedPrice(subtotal) }}</p>
          <p>{{ getFormattedPrice(shippingTotal) }}</p>
          <p>{{ getFormattedPrice(taxedPrice) }}</p>
        </div>
      </div>

      <div class="b-b-1 my-3 b-scheppach-primary-700"></div>
      <div
        class="flex items-center justify-between text-[24px] font-700 c-scheppach-primary-500"
      >
        <p>{{ t("checkout.totalLabel") }}</p>
        <p>{{ getFormattedPrice(totalPrice) }}</p>
      </div>

      <div v-if="requireTos" class="mt-3">
        <ScheppachCheckbox v-model="tosAccepted" :label="t('form.privacy')" />
      </div>

      <CheckoutPayUponInvoiceLegal
        v-if="
          selectedPaymentMethod?.shortName ==
            PAY_PAL_PAY_UPON_INVOICE_SHORT_NAME && currentStep === 1 // PAYMENT_SELECTION
        "
      />

      <CheckoutButtonAmazonPay
        v-if="
          currentStep === CheckoutStep.ORDER_VERIFICATION &&
          selectedPaymentMethod?.shortName === AMAZON_PAY_PAYMENT_HANDLER &&
          !amazonPayExpressMode
        "
        :disabled="disabled"
      />
      <button
        v-else
        :disabled="disabled"
        class="px-6 py-2 c-scheppach-shades-0 rd uppercase w-full mt-5 mb-2"
        :class="[
          !disabled
            ? 'bg-scheppach-primary-500 hover:bg-scheppach-primary-700'
            : 'bg-scheppach-neutral-300 cursor-not-allowed',
        ]"
        type="button"
        @click="guardeNextStep"
      >
        {{ buttonLabel }}
      </button>
    </div>
  </div>
</template>
